import axios from "axios";
import { createContext, useReducer } from "react";
import { ActionMap, Image } from "../utils/Types";

export enum ImageActions {
  Set = "SET",
  Append = "APPEND",
  UpdateCount = "UPDATE_COUNT",
  Remove = "REMOVE",
  Restrict = "RESTRICT",
  Flip = "FLIP",
  Compress = "COMPRESS",
}

type id = number;
export enum ImageType {
  Monster = "MONSTER",
  Character = "CHARACTER",
  Background = "BACKGROUND",
  Projectile = "PROJECTILE",
}
type UserPayload = {
  [ImageActions.Set]: { type: ImageType; images: Image | Image[] };
  [ImageActions.Append]: { type: ImageType; images: Image | Image[] };
  [ImageActions.UpdateCount]: { type: ImageType; count: number };
  [ImageActions.Remove]: { type: ImageType; id: id };
  [ImageActions.Restrict]: { type: ImageType; id: id };
  [ImageActions.Flip]: { type: ImageType; id: id };
  [ImageActions.Compress]: { type: ImageType; id: id };
};

export type ImageActionMap = ActionMap<UserPayload>[keyof ActionMap<UserPayload>];

export type ImageData = {
  [key in ImageType]: {
    count: number;
    images: Image[];
  };
};

export const imageReducer = (state: ImageData, action: ImageActionMap) => {
  switch (action.type) {
    case ImageActions.Set:
      if (action.payload.images instanceof Array) {
        state[action.payload.type].images = action.payload.images;
      } else {
        state[action.payload.type].images = [action.payload.images];
      }
      return state;
    case ImageActions.Append:
      return {
        ...state,
        [action.payload.type]: { ...state[action.payload.type], images: state[action.payload.type].images.concat(action.payload.images) },
      };
    case ImageActions.UpdateCount:
      state[action.payload.type].count = action.payload.count;
      return state;
    case ImageActions.Remove:
      return {
        ...state,
        [action.payload.type]: { ...state[action.payload.type], images: state[action.payload.type].images.filter((x) => x.id !== action.payload.id) },
      };
    case ImageActions.Restrict:
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          images: state[action.payload.type].images.map((x) => {
            if (x.id === action.payload.id) {
              x.is_banned = true;
              x.is_private = true;
            }
            return x;
          }),
        },
      };
    case ImageActions.Flip:
      return state;
    case ImageActions.Compress:
      return state;
    default:
      return state;
  }
};

const ImageContext = createContext<{ state: ImageData; dispatch: React.Dispatch<ImageActionMap> }>({
  state: {} as ImageData,
  dispatch: () => null,
});

const ImageProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(
    imageReducer,
    Object.fromEntries(
      Object.values(ImageType).map((key) => {
        return [[key as ImageType], { images: [] as Image[], count: 0 }];
      })
    )
  );
  return <ImageContext.Provider value={{ state, dispatch }}>{children}</ImageContext.Provider>;
};

export { ImageContext, ImageProvider };
