import { createContext, useReducer, useState } from "react";
import BlackBgBackdrop from "../components/basic/BlackBgBackdrop";
import { labels } from "../components/userlist/MainTable";
import UserPopup from "../components/userlist/UserPopup";
import { ActionMap, User } from "../utils/Types";

export enum UserActions {
  Set = "SET",
  Append = "APPEND",
  Ban = "BAN",
  UnBan = "UNBAN",
  TimeOut = "TIMEOUT",
  UnTimeOut = "UNTIMEOUT",
  ToggleRestrictUpload = "TOGGLE_RESTRICT_UPLOAD",
  UpdateCount = "UPDATE_COUNT",
  Sort = "SORT",
  ShowSingleUser = "SHOW_SINGLE_USER",
  CloseSingleUser = "CLOSE_SINGLE_USER",
}

type id = number;
type UserPayload = {
  [UserActions.Ban]: { id: id; reason: string };
  [UserActions.UnBan]: id;
  [UserActions.TimeOut]: { id: id; reason: string; days: number };
  [UserActions.UnTimeOut]: id;
  [UserActions.ToggleRestrictUpload]: { id: id; valueToSet: boolean };
  [UserActions.Append]: User | User[];
  [UserActions.Set]: User | User[];
  [UserActions.UpdateCount]: number;
  [UserActions.Sort]: { label: typeof labels[number]; direction: boolean };
  [UserActions.ShowSingleUser]: id;
  [UserActions.CloseSingleUser]: undefined;
};

export type UserListActions = ActionMap<UserPayload>[keyof ActionMap<UserPayload>];

export interface UserData {
  users: User[];
  count: number;
  userSelected: User | null;
}

export const userListReducer = (state: UserData, action: UserListActions) => {
  switch (action.type) {
    case UserActions.UpdateCount:
      return {
        ...state,
        count: action.payload,
      };
    case UserActions.Append:
      return {
        ...state,
        users: state.users.concat(action.payload),
      };
    case UserActions.Ban:
      let user_to_ban = state.users.find((user) => user.id === action.payload.id);
      if (user_to_ban) {
        user_to_ban.is_banned = true;
        user_to_ban.ban_reason = action.payload.reason;
      } else {
        console.error(`No user with the id ${action.payload.id} found.`);
      }
      return state;
    case UserActions.UnBan:
      let user_to_unban = state.users.find((user) => user.id === action.payload);
      if (user_to_unban && user_to_unban?.is_banned) {
        user_to_unban.is_banned = false;
        user_to_unban.ban_reason = null;
      } else {
        console.error(`No user with the id ${action.payload} that is banned found.`);
      }
      return state;
    case UserActions.Set:
      if (action.payload instanceof Array) {
        state.users = action.payload;
      } else {
        state.users = [action.payload];
      }
      return state;
    case UserActions.ToggleRestrictUpload:
      return {
        ...state,
        users: state.users.map((user, i) => {
          if (user.id === action.payload.id) {
            user.lost_upload_privileges = action.payload.valueToSet;
          }
          return user;
        }),
      };
    case UserActions.TimeOut:
      let user_to_timeout = state.users.find((user) => user.id === action.payload.id);
      if (user_to_timeout) {
        let today = new Date();
        let timeoutDate = new Date();
        timeoutDate.setDate(today.getDate() + action.payload.days);
        user_to_timeout.is_timed_out = timeoutDate.toLocaleDateString();
        user_to_timeout.ban_reason = action.payload.reason;
      } else {
        console.error(`No user with the id ${action.payload.id} found.`);
      }
      return state;
    case UserActions.UnTimeOut:
      let user_to_untimeout = state.users.find((user) => user.id === action.payload);
      if (user_to_untimeout) {
        user_to_untimeout.is_timed_out = null;
        user_to_untimeout.ban_reason = null;
        // axios.post(`/api/users/${action.payload}/remove_timeout/`);
      } else {
        console.error(`No user with the id ${action.payload} found.`);
      }
      return state;
    case UserActions.Sort:
      //finds the sorting function in that object below and applies the direction boolean
      //true should be up/bigger/positive
      return { ...state, users: state.users.sort(sortingFunctions[action.payload.label].apply(null, [action.payload.direction])) };
    case UserActions.ShowSingleUser:
      return {
        ...state,
        userSelected: state.users.find((user) => user.id === action.payload) || null,
      };
    case UserActions.CloseSingleUser:
      return {
        ...state,
        userSelected: null,
      };
    default:
      return state;
  }
};

//each label will return a function of the type (a,b)=>(-1|0|1) in order to sort the array of users
const sortingFunctions: {
  [key in typeof labels[number]]: Function;
} = {
  "id": (direction: boolean) => {
    return (a: User, b: User) => {
      if (direction) {
        return a.id > b.id ? 1 : -1;
      } else {
        return a.id < b.id ? 1 : -1;
      }
    };
  },
  "Name": (direction: boolean) => {
    return (a: User, b: User) => {
      // a-z then null
      // z-a then null, never show no names at the top
      if (direction) {
        if (a.display_name === null) {
          return 1;
        }
        if (b.display_name === null) {
          return -1;
        }
        return a.display_name.toLowerCase() > b.display_name.toLowerCase() ? 1 : -1;
      } else {
        if (a.display_name === null) {
          return 1;
        }
        if (b.display_name === null) {
          return -1;
        }
        return a.display_name.toLowerCase() < b.display_name.toLowerCase() ? 1 : -1;
      }
    };
  },
  "username": (direction: boolean) => {
    return (a: User, b: User) => {
      // a-z then null
      if (direction) {
        return a.username.toLowerCase() > b.username.toLowerCase() ? 1 : -1;
      } else {
        return a.username.toLowerCase() < b.username.toLowerCase() ? 1 : -1;
      }
    };
  },
  "Email Verified": (direction: boolean) => {
    return (a: User, b: User) => {
      if (direction) {
        return Number(a.has_valid_email) - Number(b.has_valid_email);
      } else {
        return Number(b.has_valid_email) - Number(a.has_valid_email);
      }
    };
  },
  "Stats": (direction: boolean) => {
    return (a: User, b: User) => {
      if (direction) {
        return Number(a.games_played !== null) - Number(b.games_played !== null);
      } else {
        return Number(b.games_played !== null) - Number(a.games_played !== null);
      }
    };
  },
  "Last Login": (direction: boolean) => {
    return (a: User, b: User) => {
      if (direction) {
        if (a.last_logged_in === null) {
          return -1;
        }
        if (b.last_logged_in === null) {
          return 1;
        }
        return Date.parse(a.last_logged_in) > Date.parse(b.last_logged_in) ? 1 : -1;
      } else {
        if (a.last_logged_in === null) {
          return 1;
        }
        if (b.last_logged_in === null) {
          return -1;
        }
        return Date.parse(a.last_logged_in) < Date.parse(b.last_logged_in) ? 1 : -1;
      }
    };
  },
  "Used Pro Trial": (direction: boolean) => {
    return (a: User, b: User) => {
      if (direction) {
        return Number(a.used_pro_plan_trial) - Number(b.used_pro_plan_trial);
      } else {
        return Number(b.used_pro_plan_trial) - Number(a.used_pro_plan_trial);
      }
    };
  },
  "Used Prem. Trial": (direction: boolean) => {
    return (a: User, b: User) => {
      if (direction) {
        return Number(a.used_premium_plan_trial) - Number(b.used_premium_plan_trial);
      } else {
        return Number(b.used_premium_plan_trial) - Number(a.used_premium_plan_trial);
      }
    };
  },
  "Has Plan": (direction: boolean) => {
    return (a: User, b: User) => {
      if (direction) {
        return Number(a.has_active_plan) - Number(b.has_active_plan);
      } else {
        return Number(b.has_active_plan) - Number(a.has_active_plan);
      }
    };
  },
  "Current Plan": (direction: boolean) => {
    return (a: User, b: User) => {
      const plan2num = (plan: string | null) => {
        switch (plan) {
          case "VIP":
            return 3;
          case "PRE":
            return 2;
          case "PRO":
            return 1;
          default:
            return 0;
        }
      };
      if (direction) {
        return plan2num(a.current_plan_type) - plan2num(b.current_plan_type);
      } else {
        return plan2num(b.current_plan_type) - plan2num(a.current_plan_type);
      }
    };
  },
  "Banned?": (direction: boolean) => {
    return (a: User, b: User) => {
      if (direction) {
        return Number(a.is_banned) - Number(b.is_banned);
      } else {
        return Number(b.is_banned) - Number(a.is_banned);
      }
    };
  },
  "Timed Out?": (direction: boolean) => {
    return (a: User, b: User) => {
      if (direction) {
        return Number(a.is_timed_out !== null) - Number(b.is_timed_out !== null);
      } else {
        return Number(b.is_timed_out !== null) - Number(a.is_timed_out !== null);
      }
    };
  },
  "Lost Uploads?": (direction: boolean) => {
    return (a: User, b: User) => {
      if (direction) {
        return Number(a.lost_upload_privileges) - Number(b.lost_upload_privileges);
      } else {
        return Number(b.lost_upload_privileges) - Number(a.lost_upload_privileges);
      }
    };
  },
  "Num. Images Uploaded": (direction: boolean) => {
    return (a: User, b: User) => {
      //if both are 0 then the sort wont be affected
      if (direction) {
        return a.images_uploaded_this_month - b.images_uploaded_this_month;
      } else {
        return b.images_uploaded_this_month - a.images_uploaded_this_month;
      }
    };
  },
};

const UserListContext = createContext<{ state: UserData; dispatch: React.Dispatch<UserListActions> }>({
  state: {} as UserData,
  dispatch: () => null,
});

const UserListProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(userListReducer, { count: 0, users: [] as User[], userSelected: null });
  return (
    <UserListContext.Provider value={{ state, dispatch }}>
      {children}
      {state.userSelected ? (
        <BlackBgBackdrop onClose={() => dispatch({ type: UserActions.CloseSingleUser })}>
          <UserPopup user={state.userSelected}></UserPopup>
        </BlackBgBackdrop>
      ) : (
        <></>
      )}
    </UserListContext.Provider>
  );
};

export { UserListContext, UserListProvider };
