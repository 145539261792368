import React, { useState } from "react";
import { User } from "../../utils/Types";
import Button from "../basic/Button";
import Cell from "../basic/Cell";
import Label from "../basic/Label";
import Row from "../basic/Row";
import { ban } from "../icons";
import SingleUser from "./SingleUser";

export const labels = ["id", "Name", "username", "Email Verified", "Stats", "Last Login", "Used Pro Trial", "Used Prem. Trial", "Has Plan", "Current Plan", "Lost Uploads?", "Timed Out?", "Banned?", "Num. Images Uploaded"] as const;
interface props {
  users: User[];
  onSort: Function;
  currentSort: typeof labels[number];
  direction: boolean;
}

export default function MainTable(props: props) {
  const { users } = props;

  return (
    <table className="border-collapse w-full  md:table border-[1px] border-primary-1200 dark:border-gray-500 text-center">
      <thead className="min-w-full">
        <tr className="bg-emerald-500 dark:bg-emerald-900   absolute md:static top-[-9999px] left-[-9999px] md:table-row md: md:top-auto md:left-auto ">
          {labels.map((name, i) => {
            return (
              <Label onClick={() => props.onSort(name, !props.direction)} key={i}>
                {name}
              </Label>
            );
          })}
          <Label>Actions</Label>
        </tr>
      </thead>
      <tbody className="min-w-full">
        <>
          {users.map((user, i) => {
            return (
              <Row key={i}>
                <SingleUser user={user}></SingleUser>
              </Row>
            );
          })}
        </>
      </tbody>
    </table>
  );
}
