import axios from "axios";
import React, { useContext, useState } from "react";
import { ActionMap, User } from "../../utils/Types";
import { UserActions, UserListContext } from "../../utils/UserListContext";
import Button from "../basic/Button";
import { mute, warning, ban } from "../icons";

export enum ModActionValues {
  Ban = "ban",
  UnBan = "unban",
  TimeOut = "timeout",
  UnTimeOut = "untimeout",
  ToggleRestrictUpload = "toggle_restrict_upload",
}
type ModPayload = {
  [ModActionValues.Ban]: undefined;
  [ModActionValues.UnBan]: undefined;
  [ModActionValues.TimeOut]: undefined;
  [ModActionValues.UnTimeOut]: undefined;
  [ModActionValues.ToggleRestrictUpload]: undefined;
};

export type ModActions = ActionMap<ModPayload>[keyof ActionMap<ModPayload>];

export default function ModActionButtons({ user }: { user: User }) {
  const { state, dispatch } = useContext(UserListContext);

  const [loading, setLoading] = useState(false);

  const enforce = (action: ModActions) => {
    function send(type: string, data: any = undefined) {
      return axios.post(`/api/users/${user.id}/${type.toLowerCase()}/`, data);
    }
    function getInput() {
      //todo expand this to a clickable list
      return window.prompt("What is the reason?");
    }

    setLoading(true);
    if (window.confirm(`Are you sure you want to ${action.type.toUpperCase()} the user ${user.username}?`)) {
      let payload: { reason: string | undefined; days: number | undefined } = { reason: undefined, days: undefined };
      switch (action.type) {
        case ModActionValues.Ban:
          const banReason = getInput() || "Breaking the terms and conditions.";
          payload.reason = banReason;
          send(action.type, payload)
            .then(() => {
              dispatch({
                type: UserActions.Ban,
                payload: {
                  id: user.id,
                  reason: banReason,
                },
              });
            })
            .catch((err) => {
              console.log(err.response.data);
            })
            .finally(() => {
              setLoading(false);
            });
          break;
        case ModActionValues.UnBan:
          send(action.type)
            .then(() => {
              dispatch({
                type: UserActions.UnBan,
                payload: user.id,
              });
            })
            .catch((err) => {
              console.log(err.response.data);
            })
            .finally(() => {
              setLoading(false);
            });
          break;
        case ModActionValues.ToggleRestrictUpload:
          send(action.type)
            .then(() => {
              dispatch({
                type: UserActions.ToggleRestrictUpload,
                payload: { id: user.id, valueToSet: !user.lost_upload_privileges },
              });
            })
            .catch((err) => {
              console.log(err.response.data);
            })
            .finally(() => {
              setLoading(false);
            });
          break;
        case ModActionValues.TimeOut:
          const timeoutReason = getInput() || "Breaking the terms and conditions.";
          const days = parseInt(window.prompt("How many days?") || "7");
          payload.reason = timeoutReason;
          payload.days = days;
          send(action.type, payload)
            .then(() => {
              dispatch({
                type: UserActions.TimeOut,
                payload: {
                  id: user.id,
                  reason: timeoutReason,
                  days: days,
                },
              });
            })
            .catch((err) => {
              console.log(err.response.data);
            })
            .finally(() => {
              setLoading(false);
            });
          break;
        case ModActionValues.UnTimeOut:
          send(action.type)
            .then(() => {
              dispatch({
                type: UserActions.UnTimeOut,
                payload: user.id,
              });
            })
            .catch((err) => {
              console.log(err.response.data);
            })
            .finally(() => {
              setLoading(false);
            });
          break;
      }
    } else {
      setLoading(false);
    }
  };

  return (
    <>
      {" "}
      {user.lost_upload_privileges ? (
        <Button onClick={() => enforce({ type: ModActionValues.ToggleRestrictUpload })} className={loading ? "text-gray-500 pointer-events-none" : "text-emerald-500 hover:text-emerald-400"}>
          {mute}
        </Button>
      ) : (
        <Button onClick={() => enforce({ type: ModActionValues.ToggleRestrictUpload })} className={loading ? "text-gray-500 pointer-events-none" : "text-sky-500 hover:text-sky-400"}>
          {mute}
        </Button>
      )}
      {user.is_timed_out ? (
        <Button onClick={() => enforce({ type: ModActionValues.UnTimeOut })} className={loading ? "text-gray-500 pointer-events-none" : "text-emerald-500 hover:text-emerald-400"}>
          {warning}
        </Button>
      ) : (
        <Button onClick={() => enforce({ type: ModActionValues.TimeOut })} className={loading ? "text-gray-500 pointer-events-none" : "text-yellow-500 hover:text-yellow-400"}>
          {warning}
        </Button>
      )}
      {user.is_banned ? (
        <Button onClick={() => enforce({ type: ModActionValues.UnBan })} className={loading ? "text-gray-500 pointer-events-none" : "text-emerald-500 hover:text-emerald-400"}>
          {ban}
        </Button>
      ) : (
        <Button onClick={() => enforce({ type: ModActionValues.Ban })} className={loading ? "text-gray-500 pointer-events-none" : "text-red-500 hover:text-red-400"}>
          {ban}
        </Button>
      )}
    </>
  );
}
