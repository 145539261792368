import { useState } from "react";
import { User } from "../../utils/Types";
import { checkmark, cross, filledCheck, filledCross, info } from "../icons";
import ModActionButtons from "./ModActionButtons";
import { getColor } from "./SingleUser";

interface props {
  user: User | null;
}

export default function UserPopup(props: props) {
  const { user } = props;
  const [showStatBox, setShowStatBox] = useState(false);
  return (
    <div className="flex flex-col p-3 bg-primary-100 dark:bg-primary-1200 rounded-md min-w-[20rem] gap-5 max-h-[80vh] overflow-y-scroll">
      <div className="w-full flex justify-between items-center dark:text-white">
        <p className="text-sm">{user?.id}</p>
        <p className="font-bold">{user?.username}</p>
        <p>{user?.display_name}</p>
        <div>
          {showStatBox ? (
            <>
              <div
                onClick={() => {
                  setShowStatBox(false);
                }}
                className="text-gray font-bold hover:text-red-500 cursor-pointer"
              >
                {cross}
              </div>
            </>
          ) : (
            <>
              {user?.games_played !== null ? (
                <div onClick={() => setShowStatBox(true)} className="text-sky-500 cursor-pointer hover:text-sky-400">
                  {info}
                </div>
              ) : (
                <div className="text-gray-400">{info}</div>
              )}
            </>
          )}
        </div>
      </div>
      {!showStatBox ? (
        <>
          <div className="flex justify-between dark:text-white">
            <p>Plan:</p>
            <p className={getColor(user?.current_plan_type || "")}>{user?.current_plan_type}</p>
          </div>
          <div className="flex justify-between dark:text-white">
            <p>Last Logon</p>
            <p> {new Date(user?.last_logged_in || "").toLocaleString()}</p>
          </div>
          <div className="flex justify-between dark:text-white">
            <p>Images Uploaded</p>
            <p> {user?.images_uploaded_this_month}</p>
          </div>
          <table className="text-white border-collapse table-fixed">
            <tbody>
              <tr>
                <td className="w-[50%] border-r-2 border-white">Email Verified</td>
                <td className="w-[50%] ">{user?.has_valid_email ? <div className="text-emerald-500 w-full flex justify-center">{checkmark}</div> : <div className="text-red-500 w-full flex justify-center">{cross}</div>}</td>
              </tr>
              <tr>
                <td className="w-[50%] border-r-2 border-white">Used Pro Trial</td>
                <td className="w-[50%] ">{user?.used_pro_plan_trial ? <div className="text-emerald-500 w-full flex justify-center">{checkmark}</div> : <div className="text-red-500 w-full flex justify-center">{cross}</div>}</td>
              </tr>
              <tr>
                <td className="w-[50%] border-r-2 border-white">Used Prem. Trial</td>
                <td className="w-[50%] ">{user?.used_premium_plan_trial ? <div className="text-emerald-500 w-full flex justify-center">{checkmark}</div> : <div className="text-red-500 w-full flex justify-center">{cross}</div>}</td>
              </tr>
              <tr>
                <td className="w-[50%] border-r-2 border-white">Has Current Plan</td>
                <td className="w-[50%] ">{user?.has_active_plan ? <div className="text-emerald-500 w-full flex justify-center">{checkmark}</div> : <div className="text-red-500 w-full flex justify-center">{cross}</div>}</td>
              </tr>
            </tbody>
          </table>

          <table className="text-white border-collapse table-fixed">
            <tbody>
              <tr>
                <td className="w-[50%] border-r-2 border-white">Lost Upload</td>
                <td className="w-[50%] ">{user?.lost_upload_privileges ? <div className="text-red-500  w-full flex justify-center">{filledCheck}</div> : <div className="text-emerald-500  w-full flex justify-center">{filledCross}</div>}</td>
              </tr>
              <tr>
                <td className="w-[50%] border-r-2 border-white">Timed Out</td>
                <td className="w-[50%] ">{user?.is_timed_out ? <div className="text-red-500  w-full flex justify-center">{filledCheck}</div> : <div className="text-emerald-500  w-full flex justify-center">{filledCross}</div>}</td>
              </tr>
              <tr>
                <td className="w-[50%] border-r-2 border-white">Banned</td>
                <td className="w-[50%] ">{user?.is_banned ? <div className="text-red-500  w-full flex justify-center">{filledCheck}</div> : <div className="text-emerald-500  w-full flex justify-center">{filledCross}</div>}</td>
              </tr>
            </tbody>
          </table>
          <div className="flex justify-center gap-2">
            {user ? (
              user.is_superuser ? (
                <div className="flex gap-1 justify-center items-center">
                  <p className="text-gray-400 italic">Admin</p>
                </div>
              ) : (
                <ModActionButtons user={user}></ModActionButtons>
              )
            ) : (
              <></>
            )}
          </div>
        </>
      ) : (
        <>
          <table className="text-white border-collapse table-fixed border-b-[1px]">
            <thead>
              <tr>
                <td className="p-2 border-[1px]">Game</td>
                <td className="p-2 border-[1px]">Played</td>
                <td className="p-2 border-[1px]">Reset</td>
                <td className="p-2 border-[1px]">Last Played</td>
              </tr>
            </thead>
            <tbody>
              {Object.keys(user?.games_played?.games || {}).map((key) => {
                return (
                  <tr>
                    {user && user.games_played && user.games_played.games ? (
                      <>
                        <td className="p-2 border-x-[1px]">{key}</td>
                        <td className="p-2 border-x-[1px]">{user?.games_played?.games[key].times_played}</td>
                        <td className="p-2 border-x-[1px]">{user?.games_played?.games[key].times_reset}</td>
                        <td className="p-2 border-x-[1px]">{new Date(user?.games_played?.games[key].last_play_time).toLocaleString()}</td>
                      </>
                    ) : (
                      <></>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
}
