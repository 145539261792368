import React, { useContext } from "react";
import { User } from "../../utils/Types";
import { UserListContext, UserActions } from "../../utils/UserListContext";
import Cell from "../basic/Cell";
import { checkmark, cross, filledCheck, filledCross, info } from "../icons";
import { labels } from "./MainTable";
import ModActionButtons from "./ModActionButtons";

interface props {
  user: User;
}

export const getColor = (type: string | null) => {
  switch (type) {
    case "VIP":
      return "text-yellow-500 dark:text-yellow-400 font-bold";
    case "PRE":
      return "text-emerald-500 dark:text-emerald-400 font-bold";
    case "PRO":
      return "text-blue-500 dark:text-blue-400 font-bold";
    default:
      return "text-gray-400 dark:text-gray-500";
  }
};

export default function SingleUser(props: props) {
  const { user } = props;
  const { dispatch } = useContext(UserListContext);

  return (
    <>
      <Cell label={labels[0]}>
        <div onClick={() => dispatch({ type: UserActions.ShowSingleUser, payload: user.id })}>{user.id}</div>
      </Cell>
      <Cell label={labels[1]}>{user.display_name}</Cell>
      <Cell label={labels[2]}>{user.username}</Cell>
      <Cell label={labels[3]}>{user.has_valid_email ? <div className="text-emerald-500">{checkmark}</div> : <div className="text-red-500">{cross}</div>}</Cell>
      <Cell label={labels[4]}>
        {user.games_played !== null ? (
          <div onClick={() => dispatch({ type: UserActions.ShowSingleUser, payload: user.id })} className="text-sky-500 cursor-pointer">
            {info}
          </div>
        ) : (
          <div className="text-gray-400">{info}</div>
        )}
      </Cell>
      <Cell className="whitespace-nowrap" label={labels[5]}>
        {new Date(user.last_logged_in).toLocaleString()}
      </Cell>
      <Cell label={labels[6]}>{user.used_pro_plan_trial ? <div className="text-emerald-500">{checkmark}</div> : <div className="text-red-500">{cross}</div>}</Cell>
      <Cell label={labels[7]}>{user.used_premium_plan_trial ? <div className="text-emerald-500">{checkmark}</div> : <div className="text-red-500">{cross}</div>}</Cell>
      <Cell label={labels[8]}>{user.has_active_plan ? <div className="text-emerald-500">{checkmark}</div> : <div className="text-red-500">{cross}</div>}</Cell>
      <Cell className={getColor(user.current_plan_type)} label={labels[9]}>
        {user.current_plan_type || "none"}
      </Cell>
      <Cell label={labels[10]}>{user.lost_upload_privileges ? <div className="text-red-500">{filledCheck}</div> : <div className="text-emerald-500">{filledCross}</div>}</Cell>
      <Cell label={labels[11]}>{user.is_timed_out ? <div className="text-red-500">{filledCheck}</div> : <div className="text-emerald-500">{filledCross}</div>}</Cell>
      <Cell label={labels[12]}>{user.is_banned ? <div className="text-red-500">{filledCheck}</div> : <div className="text-emerald-500">{filledCross}</div>}</Cell>
      <Cell label={labels[13]}>{user.images_uploaded_this_month || 0}</Cell>
      {user.is_superuser ? (
        <Cell label="Actions">
          <div className="flex gap-1 justify-center items-center">
            <p className="text-gray-400 italic">Admin</p>
          </div>
        </Cell>
      ) : (
        <Cell label="Actions">
          <div className="flex gap-1 justify-center items-center">
            <ModActionButtons user={user}></ModActionButtons>
          </div>
        </Cell>
      )}
    </>
  );
}
