import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { ImageActions, ImageContext, ImageType } from "../../utils/ImagesContext";
import { Image } from "../../utils/Types";
import ImageViewer from "./ImageViewer";

export default function Images() {
  const [loading, setLoading] = useState(false);
  const [partsLoaded, setPartsLoaded] = useState(0);
  const imageCtx = useContext(ImageContext);
  useEffect(() => {
    document.title = "Images | Admin Page";
    setLoading(true);
    for (let key of Object.values(ImageType)) {
      const pathName = key.toLowerCase() + "s";
      if (!(imageCtx.state[key as ImageType] && imageCtx.state[key as ImageType].images && imageCtx.state[key as ImageType].images.length)) {
        axios
          .get(`/api/images/${pathName}/list_all/?latest=true`)
          .then((res) => {
            imageCtx.dispatch({ type: ImageActions.Set, payload: { type: key as ImageType, images: res.data.results as Image[] } });
            imageCtx.dispatch({ type: ImageActions.UpdateCount, payload: { type: key as ImageType, count: res.data.count } });
          })
          .finally(() => {
            setPartsLoaded((old) => old + 1);
          });
      } else {
        setPartsLoaded((old) => old + 1);
      }
    }
  }, [imageCtx.state, imageCtx]);
  useEffect(() => {
    if (partsLoaded >= 4) {
      setLoading(false);
    }
  }, [partsLoaded]);
  const loadMore = (type: ImageType) => {
    axios.get(`/api/images/${type.toLowerCase() + "s"}/list_all/?latest=true&limit=50&offset=${imageCtx.state[type].images.length}`).then((res) => {
      imageCtx.dispatch({ type: ImageActions.Append, payload: { type: type, images: res.data.results as Image[] } });
      imageCtx.dispatch({ type: ImageActions.UpdateCount, payload: { type: type, count: res.data.count } });
    });
  };
  return (
    <div className="flex flex-col gap-5">
      {loading ? <p>loading</p> : <></>}
      <h4>CHARACTER</h4>
      <ImageViewer
        type={ImageType.Character}
        moreToLoad={imageCtx.state.CHARACTER.count - imageCtx.state.CHARACTER.images.length}
        onLoadMore={() => {
          loadMore(ImageType.Character);
        }}
        images={imageCtx.state.CHARACTER.images}
      ></ImageViewer>
      <h4>MONSTER</h4>
      <ImageViewer
        type={ImageType.Monster}
        moreToLoad={imageCtx.state.MONSTER.count - imageCtx.state.MONSTER.images.length}
        onLoadMore={() => {
          loadMore(ImageType.Monster);
        }}
        images={imageCtx.state.MONSTER.images}
      ></ImageViewer>
      <h4>PROJECTILE</h4>
      <ImageViewer
        type={ImageType.Projectile}
        moreToLoad={imageCtx.state.PROJECTILE.count - imageCtx.state.PROJECTILE.images.length}
        onLoadMore={() => {
          loadMore(ImageType.Projectile);
        }}
        images={imageCtx.state.PROJECTILE.images}
      ></ImageViewer>
      <h4>BACKGROUND</h4>
      <ImageViewer
        type={ImageType.Background}
        moreToLoad={imageCtx.state.BACKGROUND.count - imageCtx.state.BACKGROUND.images.length}
        onLoadMore={() => {
          loadMore(ImageType.Background);
        }}
        images={imageCtx.state.BACKGROUND.images}
      ></ImageViewer>
    </div>
  );
}
