export interface BackendError {
  status: ErrorCode;
  messages: readonly string[];
}

enum ErrorCode {
  HTTP_403 = 403,
}

enum ErrorName {
  CSRF = "CSRF",
}

export const BackendErrors: Record<ErrorName, BackendError> = {
  [ErrorName.CSRF]: {
    status: 403,
    messages: ["CSRF Failed: CSRF token missing.", "CSRF Failed: CSRF token from the 'X-Csrftoken' HTTP header incorrect."],
  },
} as const;
