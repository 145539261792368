import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { User } from "../../utils/Types";
import { UserActions, UserListContext } from "../../utils/UserListContext";
import Button from "../basic/Button";
import MainTable, { labels } from "./MainTable";

export default function UserList() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const userListCtx = useContext(UserListContext);
  const [sortingDirection, setSortingDirection] = useState(false);
  const [currentSort, setCurrentSort] = useState<typeof labels[number]>("id");
  const [loadAll, setLoadAll] = useState(false);

  useEffect(() => {
    document.title = "Users | Admin Page";
    setLoading(true);
    if (userListCtx.state.users && userListCtx.state.users.length) {
      setLoading(false);
    } else {
      axios
        .get("/api/users/list_info/?latest=true")
        .then((res) => {
          console.log(res.data);
          userListCtx.dispatch({ type: UserActions.UpdateCount, payload: res.data.count });
          userListCtx.dispatch({ type: UserActions.Set, payload: res.data.results as User[] });
        })
        .catch((err) => {
          if (err.response.status === 403) {
            navigate("/login");
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [userListCtx, navigate]);

  const loadMore = () => {
    console.log("hi");
    setLoading(true);
    if (userListCtx.state.users.length >= userListCtx.state.count) {
      setLoading(false);
      return;
    }
    axios
      .get(`/api/users/list_info/?limit=50&offset=${userListCtx.state.users.length}`)
      .then((res) => {
        console.log(res.data);
        userListCtx.dispatch({ type: UserActions.UpdateCount, payload: res.data.count });
        userListCtx.dispatch({ type: UserActions.Append, payload: res.data.results as User[] });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  const sortData = (label: typeof labels[number], direction: boolean) => {
    setCurrentSort(label);
    setSortingDirection(direction);
    userListCtx.dispatch({ type: UserActions.Sort, payload: { label: label, direction: direction } });
  };

  const loadUsers = (latest: boolean) => {
    setLoading(true);
    axios
      .get(`/api/users/list_info/?latest=${latest}`)
      .then((res) => {
        console.log(res.data);
        userListCtx.dispatch({ type: UserActions.UpdateCount, payload: res.data.count });
        userListCtx.dispatch({ type: UserActions.Set, payload: res.data.results as User[] });
        setLoadAll(!latest);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="w-full overflow-scroll">
      <MainTable currentSort={currentSort} direction={sortingDirection} onSort={sortData} users={userListCtx.state.users || []}></MainTable>
      {loading ? <p>loading...</p> : <></>}
      <div className="flex justify-center align-center w-screen h-20">
        {userListCtx.state.users && userListCtx.state.count > userListCtx.state.users.length ? (
          loadAll ? (
            <div className="flex gap-1 justify-center items-center w-full">
              <Button
                className="text-primary-600 dark:text-white font-bold"
                onClick={() => {
                  loadMore();
                }}
              >
                Load 50 ({userListCtx.state.count - userListCtx.state.users.length} more)
              </Button>
              <Button
                className="text-primary-600 dark:text-white font-bold"
                onClick={() => {
                  loadUsers(true);
                }}
              >
                Load only 50 latest
              </Button>
            </div>
          ) : (
            <div className="flex gap-1 justify-center items-center w-full">
              <Button
                className="text-primary-600 dark:text-white font-bold"
                onClick={() => {
                  loadMore();
                }}
              >
                Load 50 ({userListCtx.state.count - userListCtx.state.users.length} more)
              </Button>
              <Button
                className="text-primary-600 dark:text-white font-bold"
                onClick={() => {
                  loadUsers(false);
                }}
              >
                Load All
              </Button>
            </div>
          )
        ) : (
          <p className="text-emerald-600 dark:text-emerald-300 text-md">Done.</p>
        )}
      </div>
    </div>
  );
}
