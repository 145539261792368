import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { WordlistActions, WordlistContext } from "../../utils/WordlistsContext";
import Button from "../basic/Button";
import WordList from "./WordList";

export default function WordListManager() {
  const [loading, setLoading] = useState(false);
  const [loadAll, setLoadAll] = useState(false);
  const { state, dispatch } = useContext(WordlistContext);
  useEffect(() => {
    document.title = "Wordlists | Admin Page";
    setLoading(true);
    if (state.wordlists.length === 0) {
      loadWordlists(true);
    } else {
      setLoading(false);
    }
  }, [state, dispatch]);
  const loadMore = () => {
    setLoading(true);
    if (state.wordlists.length >= state.count) {
      setLoading(false);
      return;
    }
    axios
      .get(`/api/wordlists/list_all/?limit=50&offset=${state.wordlists.length}&latest=${!loadAll}`)
      .then((res) => {
        dispatch({ type: WordlistActions.UpdateCount, payload: res.data.count });
        dispatch({ type: WordlistActions.Append, payload: res.data.results });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  const loadWordlists = (latest: boolean) => {
    setLoading(true);
    axios
      .get(`/api/wordlists/list_all/?latest=${latest}`)
      .then((res) => {
        dispatch({ type: WordlistActions.UpdateCount, payload: res.data.count });
        dispatch({ type: WordlistActions.Set, payload: res.data.results });
        setLoadAll(!latest);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div className="flex flex-col gap-1 justify-center items-center">
      {state.wordlists.map((item) => {
        return <WordList key={item.id} list={item}></WordList>;
      })}
      <div className="flex justify-center align-center w-screen h-20">
        {state.wordlists && state.count > state.wordlists.length ? (
          loadAll ? (
            <div className="flex gap-1 justify-center items-center w-full">
              <Button
                className="text-primary-600 dark:text-white font-bold"
                onClick={() => {
                  loadMore();
                }}
              >
                Load 50 ({state.count - state.wordlists.length} more)
              </Button>
              <Button
                className="text-primary-600 dark:text-white font-bold"
                onClick={() => {
                  loadWordlists(true);
                }}
              >
                Load only 50 latest
              </Button>
            </div>
          ) : (
            <div className="flex gap-1 justify-center items-center w-full">
              <Button
                className="text-primary-600 dark:text-white font-bold"
                onClick={() => {
                  loadMore();
                }}
              >
                Load 50 ({state.count - state.wordlists.length} more)
              </Button>
              <Button
                className="text-primary-600 dark:text-white font-bold"
                onClick={() => {
                  loadWordlists(false);
                }}
              >
                Load All
              </Button>
            </div>
          )
        ) : (
          <p className="text-emerald-600 dark:text-emerald-300 text-md">Done.</p>
        )}
      </div>
    </div>
  );
}
