import axios from "axios";
import React, { useContext, useState } from "react";
import { ImageActions, ImageContext, ImageType } from "../../utils/ImagesContext";
import { Image } from "../../utils/Types";
import { UserActions, UserListContext } from "../../utils/UserListContext";
import { ban, bin, flip, globe } from "../icons";

interface props {
  image: Image;
  type: ImageType;
}

export default function SingleImage(props: props) {
  const { image } = props;
  const [loading, setLoading] = useState(false);
  const { state, dispatch } = useContext(ImageContext);
  const userCtx = useContext(UserListContext);
  const [flipped, setFlipped] = useState(false);
  const banImage = () => {
    setLoading(true);
    if (window.confirm(`Are you sure you want to BAN the image titled '${image.name}' (${image.id}) by ${image.created_by.username}?`)) {
      axios
        .patch(`/api/images/${props.type.toLowerCase() + "s"}/${image.id}/ban/`)
        .then(() => {
          dispatch({ type: ImageActions.Restrict, payload: { type: props.type, id: image.id } });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  };
  const removeImage = () => {
    setLoading(true);
    if (window.confirm(`Are you sure you want to DELETE the image titled '${image.name}' (${image.id}) by ${image.created_by.username}?`)) {
      axios
        .delete(`/api/images/${props.type.toLowerCase() + "s"}/${image.id}/remove/`)
        .then(() => {
          dispatch({ type: ImageActions.Remove, payload: { type: props.type, id: image.id } });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  };
  const flipImage = () => {
    setLoading(true);
    if (window.confirm(`Are you sure you want to FLIP the image titled '${image.name}' (${image.id}) by ${image.created_by.username}?`)) {
      axios
        .patch(`/api/images/${props.type.toLowerCase() + "s"}/${image.id}/flip/`)
        .then(() => {
          setFlipped(true);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="w-40 h-40 dark:border-white border-primary-900 rounded-md border-[1px] p-2 object-contain overflow-hidden relative">
      <p className="absolute top-0 left-1 dark:text-white">{image.id}</p>
      <div
        className="absolute bottom-0 left-1 dark:text-white text-xs cursor-pointer select-none"
        onClick={() => {
          userCtx.dispatch({ type: UserActions.ShowSingleUser, payload: image.created_by.id });
        }}
      >
        <p className="text-primary dark:text-white  select-none">{image.created_by.username}</p>
      </div>
      {image.is_private ? <></> : <div className="absolute top-0 right-0 text-teal-500">{globe}</div>}
      {image.is_banned ? <div className="absolute top-0 right-0 text-amber-500">{ban}</div> : <></>}
      {flipped ? <div className="absolute top-0 right-[2rem] text-emerald-500">{flip}</div> : <></>}
      <div className="actions flex justify-center items-center gap-1 absolute bottom-0 right-0 p-1">
        <div onClick={() => flipImage()} className={" " + (loading ? "text-gray-500 pointer-events-none" : "text-emerald-500 hover:text-emerald-400 cursor-pointer")}>
          {flip}
        </div>
        <div onClick={() => banImage()} className={" " + (loading ? "text-gray-500 pointer-events-none" : "text-red-500 hover:text-red-400 cursor-pointer")}>
          {ban}
        </div>
        <div onClick={() => removeImage()} className={" " + (loading ? "text-gray-500 pointer-events-none" : "text-red-500 hover:text-red-400 cursor-pointer")}>
          {bin}
        </div>
      </div>
      <img className="w-full h-full object-contain" src={image.thumbnail} alt={image.name}></img>
    </div>
  );
}
