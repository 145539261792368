import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext, AuthTypes } from "../utils/AuthContext";
import BlackBgBackdrop from "./basic/BlackBgBackdrop";
import NavbarButton from "./basic/NavbarButton";

export default function Navbar() {
  const [navOpen, setNavOpen] = useState(false);
  const { state, dispatch } = useContext(AuthContext);
  const handleClick = () => {
    setNavOpen(!navOpen);
  };
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (!state.isAuthenticated) {
      axios
        .get("/api/users/me/")
        .then((res) => {
          if (res.status === 200) {
            dispatch({ type: AuthTypes.LogIn });
            dispatch({ type: AuthTypes.UpdateUserData, payload: res.data });
          }
        })
        .catch(() => {
          localStorage.removeItem("rememberme");
          navigate("/login");
          return;
        });
    }
  }, []);
  useEffect(() => {
    setNavOpen(false);
  }, [location]);
  return (
    <>
      <div className="w-full h-20 flex justify-between items-center flex-row bg-primary-300 dark:bg-primary-1200 px-4">
        <h2 className="text-2xl title whitespace-nowrap text-primary-600 dark:text-white">whatstheword.io admin site</h2>
        <div className="lg:flex hidden">
          {state.isAuthenticated ? (
            <div className="w-full h-full flex items-center px-10 gap-5">
              <NavbarButton text="Dashboard" href="/" />
              <NavbarButton text="User List" href="/users" />
              <NavbarButton text="Images" href="/images" />
              <NavbarButton text="WordLists" href="/wordlists" />
            </div>
          ) : (
            <></>
          )}
          {state.isAuthenticated ? <NavbarButton text="Logout" href="/logout" /> : <NavbarButton text="Login" href="/login" />}
        </div>
        <div className="flex justify-center items-center h-full w-20 lg:hidden" onClick={handleClick}>
          {!navOpen ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8  hover:cursor-pointer hover:text-primary-300 dark:text-primary-300 dark:hover:text-primary-100 text-primary-900"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8  hover:cursor-pointer hover:text-primary-300 dark:text-primary-300 dark:hover:text-primary-100 text-primary-900"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          )}
        </div>
      </div>
      {navOpen ? (
        <BlackBgBackdrop
          onClose={() => {
            setNavOpen(false);
          }}
        >
          <div className="absolute top-0 w-full flex flex-col">
            <div className="w-full h-20 flex justify-between items-center flex-row bg-primary-300 dark:bg-primary-1200 px-4">
              <h2 className="text-2xl title whitespace-nowrap text-primary-600 dark:text-white">whatstheword.io admin site</h2>
              <div className="flex justify-center items-center h-full w-20 lg:hidden" onClick={handleClick}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8  hover:cursor-pointer hover:text-primary-300 dark:text-primary-300 dark:hover:text-primary-100 text-primary-900"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </div>
            </div>
            <div className="w-full flex flex-col bg-primary-300 dark:bg-primary-1200 justify-center items-center">
              {state.isAuthenticated ? (
                <>
                  <NavbarButton className="p-3 text-lg tracking-wide" text="Dashboard" href="/" />
                  <NavbarButton className="p-3 text-lg tracking-wide" text="User List" href="/users" />
                  <NavbarButton className="p-3 text-lg tracking-wide" text="Images" href="/images" />
                  <NavbarButton className="p-3 text-lg tracking-wide" text="Wordlists" href="/wordlists" />
                  <NavbarButton className="p-3 text-lg tracking-wide" text="Logout" href="/logout" />
                </>
              ) : (
                <NavbarButton className="p-3 text-lg tracking-wide" text="Login" href="/login" />
              )}
            </div>
          </div>
        </BlackBgBackdrop>
      ) : (
        <></>
      )}
    </>
  );
}
