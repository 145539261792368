import React from "react";
import { Link } from "react-router-dom";

interface props {
  text: string;
  className?: string;
  href: string;
}

export default function NavbarButton(props: props) {
  return (
    <Link className={"text-primary-1200 dark:text-white cursor-pointer h-full text-center w-40 flex justify-center items-center hover:underline " + props.className} to={props.href}>
      <p className="m-2">{props.text}</p>
    </Link>
  );
}
