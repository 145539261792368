import axios from "axios";
import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { AuthTypes, AuthContext } from "../../utils/AuthContext";
import { useNavigate } from "react-router-dom";
import { InputGroup } from "../basic/InputGroup";
import { InputType } from "../../utils/Types";

export const Login = () => {
  useEffect(() => {
    document.title = "Login | Admin Page";
  }, []);

  const { dispatch } = useContext(AuthContext);
  const [remember, setRemember] = useState<boolean | any>(true);
  const navigate = useNavigate();

  const inputs: InputType[] = [
    {
      value: "",
      name: "username",
      type: "text",
      placeholder: " ",
      label: "Username",
      errors: [],
      required: true,
      invalid: false,
      submitted: false,
    },
    {
      value: "",
      name: "password",
      type: "password",
      placeholder: " ",
      label: "Password",
      errors: [],
      required: true,
      invalid: false,
      submitted: false,
    },
  ];
  const [usernameValues, setUsernameValues] = useState(inputs[0]);
  const [passwordValues, setPasswordValues] = useState(inputs[1]);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    setButtonDisabled(true);

    setUsernameValues((prevValues) => ({ ...prevValues, errors: [], invalid: false }));
    setPasswordValues((prevValues) => ({ ...prevValues, errors: [], invalid: false }));
    axios
      .post("/api/users/login/", { username: usernameValues.value, password: passwordValues.value, remember: remember, safety: true })
      .then((res) => {
        if (res.data.groups.includes(1) || res.data.is_superuser) {
          dispatch({ type: AuthTypes.LogIn });
          dispatch({ type: AuthTypes.UpdateUserData, payload: res.data });
          if (remember) {
            localStorage.setItem("loggedInBefore", "true");
          }
          setButtonDisabled(false);
          navigate("/users");
        } else {
          setUsernameValues((prevValues) => ({ ...prevValues, errors: ["Incorrect username or password."], invalid: true }));
          setPasswordValues((prevValues) => ({ ...prevValues, invalid: true }));
          setButtonDisabled(false);
        }
      })
      .catch((err) => {
        if (!err.response || !err.response.data) {
          setUsernameValues((prevValues) => ({ ...prevValues, invalid: true }));
          setPasswordValues((prevValues) => ({ ...prevValues, errors: ["Unable to contact the servers. Check your internet connection or try again later."], invalid: true }));
          setButtonDisabled(false);

          return;
        }
        for (let key in err.response.data) {
          let errors: string[] = [];
          if (typeof err.response.data[key] === "string") {
            errors = [err.response.data[key]];
          } else {
            errors = err.response.data[key];
          }
          switch (key) {
            case "username":
              setUsernameValues((prevValues) => ({ ...prevValues, errors: errors, invalid: true }));
              setPasswordValues((prevValues) => ({ ...prevValues, invalid: true }));
              break;
            case "password":
              setUsernameValues((prevValues) => ({ ...prevValues, invalid: true }));
              setPasswordValues((prevValues) => ({ ...prevValues, errors: errors, invalid: true }));
              break;
            default:
              setUsernameValues((prevValues) => ({ ...prevValues, errors: errors, invalid: true }));
              setPasswordValues((prevValues) => ({ ...prevValues, invalid: true }));
          }
        }
        setButtonDisabled(false);
      });
  };
  return (
    <div className="w-full h-screen flex justify-center items-center dark:bg-slate-900">
      <div className="bg-stone-50 flex-col gap-4 dark:bg-primary-1200 shadow-primary-500 dark:shadow-primary-1100 rounded-md shadow-sm w-fit h-fit w-max-[500px] h-max-[90%] p-6  flex justify-center items-center">
        <form onSubmit={(ev) => handleSubmit(ev)} className="flex flex-col w-[200px] m-2 justify-center items-center">
          <InputGroup
            className="mb-5"
            {...usernameValues}
            onChange={(ev: ChangeEvent<HTMLInputElement>) => {
              setUsernameValues((prevValues) => ({ ...prevValues, errors: [], invalid: false, value: ev.target.value }));
              setPasswordValues((prevValues) => ({ ...prevValues, errors: [], invalid: false }));
            }}
            value={usernameValues.value}
          />
          <InputGroup
            className="mb-20"
            {...passwordValues}
            onChange={(ev: ChangeEvent<HTMLInputElement>) => {
              setPasswordValues((prevValues) => ({ ...prevValues, errors: [], invalid: false, value: ev.target.value }));
              setUsernameValues((prevValues) => ({ ...prevValues, errors: [], invalid: false }));
            }}
            value={passwordValues.value}
          />

          <div className="flex items-start mb-6">
            <div className="flex items-center h-8">
              <input
                id="remember"
                type="checkbox"
                checked={remember}
                onChange={(ev) => {
                  setRemember(ev.target.checked);
                }}
                className="w-5 h-5 border border-primary-200 rounded bg-primary-50 dark:bg-primary-1100 focus:ring-3 focus:ring-primary-300"
              />
            </div>
            <label htmlFor="remember" className="ml-2 text-xs place-self-center font-thin text-gray-900 dark:text-primary-200">
              Remember me for a week
            </label>
          </div>
          {process.env.REACT_APP_SERVER_ISSUE === "true" ? (
            <>
              <h4 className="text-amber-700 text-sm dark:text-amber-300 my-1">
                We have reports of our servers being blocked by the great firewall. You might have some issues logging in. We are trying to fix it as fast as we can. We apologize for the inconvenience.
              </h4>
            </>
          ) : (
            <></>
          )}
          <button
            type="submit"
            disabled={buttonDisabled}
            className="text-white font-thin  focus:ring-4  rounded-md text-md lg:text-lg xl:text-xl w-full sm:w-auto px-5 py-1.5 text-center self-center justify-self-center disabled:bg-gray-500 disabled:text-gray-200 disabled:pointer-events-none bg-primary-700 hover:bg-primary-800 focus:ring-primary-300 focus:outline-none"
          >
            Log In
          </button>
        </form>
      </div>
    </div>
  );
};
