import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AdminUser, AuthTypes, AuthContext } from "../../utils/AuthContext";
import axios from "axios";

export default function Logout() {
  const { dispatch } = useContext(AuthContext);
  const navigate = useNavigate();
  useEffect(() => {
    axios.post("/api/users/logout/").then((res) => {
      dispatch({ type: AuthTypes.LogOut });
      dispatch({ type: AuthTypes.UpdateUserData, payload: {} as AdminUser });
      navigate("/login");
    });
  }, [dispatch, navigate]);
  return <div>Logging Out...</div>;
}
