import React from "react";
import Navbar from "./components/Navbar";
import { AuthProvider } from "./utils/AuthContext";
import { UserListProvider } from "./utils/UserListContext";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Login } from "./components/auth/Login";
import Dashboard from "./components/dashboard";
import Logout from "./components/auth/Logout";
import UserList from "./components/userlist";
import Images from "./components/images";
import { ImageProvider } from "./utils/ImagesContext";
import WordListManager from "./components/wordlists";
import { WordlistProvider } from "./utils/WordlistsContext";

function App() {
  return (
    <div className="min-h-screen bg-primary-100 dark:bg-slate-700">
      <AuthProvider>
        <UserListProvider>
          <WordlistProvider>
            <ImageProvider>
              <Router>
                <Navbar />
                <Routes>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/users" element={<UserList />} />
                  <Route path="/images" element={<Images />} />
                  <Route path="/wordlists" element={<WordListManager />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/logout" element={<Logout />} />
                </Routes>
              </Router>
            </ImageProvider>
          </WordlistProvider>
        </UserListProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
