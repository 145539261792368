import axios from "axios";
import React, { useContext, useState } from "react";
import { Wordlist } from "../../utils/Types";
import { UserActions, UserListContext } from "../../utils/UserListContext";
import { WordlistActions, WordlistContext } from "../../utils/WordlistsContext";
import Button from "../basic/Button";
import { ban, bin, globe } from "../icons";

interface props {
  list: Wordlist;
}

export default function WordList(props: props) {
  const { list } = props;
  const [loading, setLoading] = useState(false);
  const { state, dispatch } = useContext(WordlistContext);
  const userCtx = useContext(UserListContext);
  const banList = () => {
    setLoading(true);
    if (window.confirm(`Are you sure you want to BAN the wordlist starting with ${list.words[0]} (${list.words.length} words) by ${list.created_by.username}?`)) {
      axios
        .patch(`/api/wordlists/${list.id}/ban/`)
        .then(() => {
          dispatch({ type: WordlistActions.Restrict, payload: list.id });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };
  const deleteList = () => {
    setLoading(true);
    if (window.confirm(`Are you sure you want to DELETE the wordlist starting with ${list.words[0]} (${list.words.length} words) by ${list.created_by.username}?`)) {
      axios
        .delete(`/api/wordlists/${list.id}/delete/`)
        .then(() => {
          dispatch({ type: WordlistActions.Remove, payload: list.id });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };
  return (
    <div className="flex flex-col md:flex-row gap-3 items-center justify-between p-2 w-full lg:w-[700px] border-b-2 dark:border-gray-400 border-gray-500">
      <div className="flex gap-1">
        <p className="text-primary dark:text-white font-medium">{list.id}</p>
        <div className="w-6">
          {list.is_banned ? <div className="text-yellow-500">{ban}</div> : <></>}
          {list.is_private ? <></> : <div className="text-teal-500">{globe}</div>}
        </div>
        <div
          onClick={() => {
            userCtx.dispatch({ type: UserActions.ShowSingleUser, payload: list.created_by.id });
          }}
        >
          <p className="text-primary dark:text-white hover:drop-shadow-lg dark:shadow-white cursor-pointer select-none">{list.created_by.username}</p>
        </div>
      </div>
      <p className="text-primary dark:text-white font-medium">{list.words.join(", ")}</p>
      <div className="flex justify-center items-center gap-1">
        <Button onClick={() => banList()} className={loading ? "text-gray-500 pointer-events-none" : "text-amber-500 hover:text-amber-400"}>
          {ban}
        </Button>
        <Button
          onClick={() => {
            deleteList();
          }}
          className={loading ? "text-gray-500 pointer-events-none" : "text-red-500 hover:text-red-400"}
        >
          {bin}
        </Button>
      </div>
    </div>
  );
}
