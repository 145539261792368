import React, { MouseEventHandler } from "react";

interface props {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  className?: string;
  children: React.ReactNode;
}

export default function Button(props: props) {
  return (
    <button onClick={props.onClick} className={"border-[1px] border-primary-500 dark:border-white rounded-md p-2 hover:outline-1 outline outline-0 outline-primary-400 dark:outline-white " + props.className}>
      {props.children}
    </button>
  );
}
