import React from "react";
import { ImageType } from "../../utils/ImagesContext";
import { Image } from "../../utils/Types";
import SingleImage from "./SingleImage";

interface props {
  images: Image[];
  moreToLoad: number;
  onLoadMore: Function;
  type: ImageType;
}

export default function ImageViewer(props: props) {
  return (
    <div className="flex flex-wrap gap-2 h-full w-full p-5">
      {props.images.map((img) => {
        return <SingleImage type={props.type} key={img.id} image={img}></SingleImage>;
      })}
      {props.moreToLoad > 0 ? (
        <div
          onClick={() => props.onLoadMore()}
          className="w-40 h-40 dark:text-white text-primary-500  border-primary-700 dark:border-white rounded-md border-[1px] flex justify-center items-center hover:underline cursor-pointer hover:outline-[1px] hover:outline outline-primary-700 dark:outline-white"
        >
          <p className="text-center capitalize font-bold ">load more ({props.moreToLoad})</p>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
