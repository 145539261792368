import React from "react";

interface props {
  children: React.ReactNode;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLTableCellElement>;
}

export default function Cell(props: props) {
  const { className, children, onClick } = props;
  return (
    <td onClick={onClick} className={"text-blue-800 dark:text-white border-x-[1px] border-white " + className}>
      {children}
    </td>
  );
}
