import React from "react";

interface props {
  children: React.ReactNode;
  className?: string;
  label: string;
}

export default function Cell(props: props) {
  const { label, className, children } = props;
  return (
    <td
      label={label}
      className={
        className +
        " text-blue-800 dark:text-gray-300 flex md:table-cell md:border-x-[1px] border-b-[1px] border-gray-400 md:border-b-0 relative  before:min-w-[50%] before:w-[50%] before:pr-2 text-left p-1 before:font-bold md:border-l-0 md:before:hidden before:content-[attr(label)]  "
      }
    >
      {children}
    </td>
  );
}
