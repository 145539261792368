import React, { createContext, ReactNode, useReducer } from "react";
import { ActionMap } from "./Types";

export enum LicenseType {
  BASIC,
  PRO = "PRO",
  PREMIUM = "PRE",
  VIP = "VIP",
}

export interface AdminUser {
  //from backend
  id: Number;
  username: string;
  email: string;
  is_active: boolean;
  display_name: string | null;
  date_joined: string;
  has_active_plan: boolean;
  current_plan_type: LicenseType;
  has_valid_email: boolean;
  is_superuser: boolean;
  used_pro_plan_trial: boolean;
  pro_plan_trial_started_on: boolean;
  used_premium_plan_trial: boolean;
  premium_plan_trial_started_on: boolean;
  voted_posts: Record<string, { vote: boolean }>;
  read_posts: Record<string, { read: boolean }>;
  max_sessions_allowed: number;
  always_save_wordlist_across_devices: boolean;

  // used by frontend
  isAuthenticated: boolean | false;
  locallySelectedWordlist: number; //only used if max_devices >1
}

export enum AuthTypes {
  LogIn = "LOGIN",
  LogOut = "LOGOUT",
  UpdateUserData = "UPDATE_USER_DATA",
}

type UserPayload = {
  [AuthTypes.LogIn]: undefined;
  [AuthTypes.LogOut]: undefined;
  [AuthTypes.UpdateUserData]: AdminUser;
};

export type AuthActions = ActionMap<UserPayload>[keyof ActionMap<UserPayload>];

export const authReducer = (state: AdminUser, action: AuthActions) => {
  switch (action.type) {
    case AuthTypes.LogIn:
      return {
        ...state,
        isAuthenticated: true,
      };
    case AuthTypes.LogOut:
      localStorage.removeItem(state.id + "_swl");
      localStorage.removeItem("rememberme");
      return {
        ...state,
        isAuthenticated: false,
      };
    case AuthTypes.UpdateUserData:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

const AuthContext = createContext<{
  state: AdminUser;
  dispatch: React.Dispatch<AuthActions>;
}>({
  state: {} as AdminUser,
  dispatch: () => null,
});

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(authReducer, {} as AdminUser);

  return <AuthContext.Provider value={{ state, dispatch }}>{children}</AuthContext.Provider>;
};

export { AuthProvider, AuthContext };
