import React, { useEffect } from "react";

interface props {
  children: React.ReactNode;
  onClose?: Function;
  top?: boolean;
}

export default function BlackBgBackdrop(props: props) {
  useEffect(() => {
    const escListener = (ev: KeyboardEvent) => {
      if (ev.key === "Escape") {
        if (props.onClose) {
          props.onClose();
        }
        ev.preventDefault();
        ev.stopPropagation();
      }
    };
    document.addEventListener("keydown", escListener);
    return () => {
      document.removeEventListener("keydown", escListener);
    };
  }, []);
  return (
    <div
      onClick={(ev) => {
        const target = ev.target as HTMLDivElement;
        if (target.getAttribute("id") === "background") {
          if (props.onClose) {
            props.onClose();
          }
          ev.preventDefault();
          ev.stopPropagation();
        }
      }}
      id="background"
      className={"w-full overscroll-contain overscroll-y-contain h-full bg-black/50 dark:bg-black/80 top-0 left-0 z-[2000] fixed flex flex-col " + (props.top ? "" : "justify-center items-center")}
    >
      {props.children}
    </div>
  );
}
