import axios from "axios";
import { createContext, useReducer } from "react";
import { ActionMap, Wordlist } from "../utils/Types";

export enum WordlistActions {
  Set = "SET",
  Append = "APPEND",
  UpdateCount = "UPDATE_COUNT",
  Remove = "REMOVE",
  Restrict = "RESTRICT",
}

type id = number;
type UserPayload = {
  [WordlistActions.Set]: Wordlist | Wordlist[];
  [WordlistActions.Append]: Wordlist | Wordlist[];
  [WordlistActions.UpdateCount]: number;
  [WordlistActions.Remove]: id;
  [WordlistActions.Restrict]: id;
};

export type WordlistActionMap = ActionMap<UserPayload>[keyof ActionMap<UserPayload>];

export type WordlistData = {
  count: number;
  wordlists: Wordlist[];
};

export const imageReducer = (state: WordlistData, action: WordlistActionMap) => {
  switch (action.type) {
    case WordlistActions.Set:
      if (action.payload instanceof Array) {
        state.wordlists = action.payload;
      } else {
        state.wordlists = [action.payload];
      }
      return state;
    case WordlistActions.Append:
      return {
        ...state,
        wordlists: state.wordlists.concat(action.payload),
      };
    case WordlistActions.UpdateCount:
      state.count = action.payload;
      return state;
    case WordlistActions.Remove:
      return {
        ...state,
        wordlists: state.wordlists.filter((x) => x.id !== action.payload),
      };
    case WordlistActions.Restrict:
      return {
        ...state,
        wordlists: state.wordlists.map((x) => {
          if (x.id === action.payload) {
            x.is_banned = true;
          }
          return x;
        }),
      };
  }
};

const WordlistContext = createContext<{ state: WordlistData; dispatch: React.Dispatch<WordlistActionMap> }>({
  state: {} as WordlistData,
  dispatch: () => null,
});

const WordlistProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(imageReducer, {
    wordlists: [],
    count: 0,
  });
  return <WordlistContext.Provider value={{ state, dispatch }}>{children}</WordlistContext.Provider>;
};

export { WordlistContext, WordlistProvider };
